<!--
 * @Author: your name
 * @Date: 2021-03-23 17:57:10
 * @LastEditTime: 2021-10-20 15:27:21
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \test\src\components\res\SunSpecialRes\Index.vue
-->
<template>
    <div>
        <div v-if="loadAccount().role == '学生'" class="subject-select">
            <span>学科：</span>
            <span v-for="item in subjectList" :key="item.subjectIdx" class="item-info" :class="{ 'active-item': item.subjectIdx == changeSubject }" @click="onChangeSubject(item.subjectIdx)">
                {{ item.subjectName }}
            </span>
        </div>
        <div class="special-container" v-if="isValidArray(resourceList)">
            <!-- <div class="series-box clearfix" v-show="item1.SeriesList.length>0" v-for="(item1,index) in resourceList" :key="index"> -->
                <!-- 产品说隐藏掉 2021-5-8 add by daqing end -->
                <!-- <div class="year-box" v-if="resourceList.length>1">{{resourceList[index].Year}}</div> -->
                <!-- <res-item class="series-item" v-for="(item2, idx) in item1.SeriesList" :key="idx" :info="item2"></res-item> -->
            <!-- </div> -->
            <div class="series-box clearfix">
                <res-item class="series-item" v-for="(item, idx) in resourceList" :key="idx" :info="item"></res-item>
            </div>
        </div>
        <img v-else class='no-result-img'  src="/static/images/res/no-resource1.png" alt="" />
    </div>
</template>
    
<script>
import ResItem from './Widgets/ResItem.vue'
export default {
    name: "",
    components: {
        ResItem,
    },
    props:['fuxiPeriod'],
    data () {
        return {
            resourceList: [],
            subjectList: [],
            changeSubject: null,
            subjectIdx: null,
        };
    },
    watch: {
        '$store.state.loadend': function(val) {
            if (val) {
                this.$nextTick(() => {
                    this.getNewSCSeriesList();
                });
            }
        }
    },
    methods: {
        getCourseId(){
            return new Promise((resolve,reject)=>{
                this.getCourseConfig().then(res=>{
                    const data = res.data.data;
                    if (this.isValidArray(data)) {
                        let courseId = data.find(item => item.name == '同步测评').courseId;
                        if (courseId) return resolve(courseId);
                    }

                    this.GetCourseInfoByName().then(courseId => resolve(courseId));
                });
            })
        },
        getNewSCSeriesList(cId) {
            const zoneId = this.getSessionVal("sc_zone");
            const periodId = this.getSunAccount('sc_period');
            const subjectId = this.getSunAccount('sc_subject');
            const courseId = cId || $(".nav-course.isActive").attr("courseid");
            const schoolId = this.getSunAccount('sc_school');

            const param = {
                ZoneID: zoneId,
                CourseID: courseId,
                PeriodID: periodId,
                SubjectID: subjectId
            }

            if(this.loadAccount().role == '学生'){
                param.SubjectID = '';
            }

            if(this.$route.path == '/Res/Ztxx' && this.loadAccount().role == '学生'){
                param.SubjectID = this.subjectIdx;
            }

            if(this.$route.path == '/Res/Gkfx' || this.$route.path == '/Res/Zkfx'){
                param.RevisePeriodID = this.fuxiPeriod;
            }

            if(this.$route.path == '/Res/Jbzy'){
                param.SchoolID = schoolId;
            }

            const api = this.getMasterDataCenterUrl() + `/api/Series?` + $.param(param);
            this.$http.get(api).then(res=>{
                if(res.data.data){
                    let data = res.data.data
                    for (let i = 0; i < data.length; i++) {
                        data[i] = this.toUpperCase(data[i])
                    }
                    this.stopLoading()
                    this.resourceList = data
                }
            })
        },
        onChangeSubject(subjectIdx) {
            this.changeSubject = this.subjectIdx = subjectIdx;
            this.$store.commit('SET_SUBJECTIDX', subjectIdx);
            this.getNewSCSeriesList();
        },
        /*
         * 获取学科
         */
        onGetSeriesSubjectList() {
            return new Promise((resolve, reject) => {
                let courseID = this.getSessionVal("stu_courseId");
                let api = this.getMasterDataCenterUrl()+`/api/Series/GetSeriesSubjectList?ZoneID=${this.getSessionVal("sc_zone")}&CourseID=${courseID}&PeriodID=${this.getSunAccount('sc_period')}`;
                this.$http.get(api).then(res => {
                    if(res?.data?.status?.success) {
                        resolve(res.data.data);
                    }
                })
            })
        }
    },  
    created() {
                
    },
    async mounted() {
        // 获取学科
        if(this.loadAccount().role == '学生') {
            this.subjectList = await this.onGetSeriesSubjectList();
            let idx = this.$store.state.subjectIdx;
            this.changeSubject = this.subjectIdx = idx ? idx : this.subjectList[0].subjectIdx;
            if (idx==null) {
                this.$store.commit('SET_SUBJECTIDX', this.changeSubject);
            }
        }

        if (this.$store.state.loadend) {
            this.$nextTick(() => {
                this.getNewSCSeriesList();
            });
        }

        if (this.$route.query.hidenav == "true") {
            this.getCourseId().then(courseId =>{
                this.getNewSCSeriesList(courseId)
            }) 
        }
    }
}
</script>
    
<style lang="css" scoped>
    .special-container{
        margin-top: 14px;
        margin-bottom: 20px;
        min-height: 154px;
        min-height: 380px;
    }
    .no-data{
        width: 100%;
        text-align: center;
        background-color: #fff;
        border: 1px solid #D4DFE7;
        padding: 20px;
        min-height: 380px;
    }
    .page-div{
        width: 100%;
        text-align: center;
        margin: 20px auto 40px;
    }
    .no-result-img{
        margin: 80px auto;
        display: block;
        width: 100%;
        max-width: 450px;
    }
    .series-box{
        margin-bottom: 20px;
        height: 100%;
        overflow: auto;
    }
    .series-box::-webkit-scrollbar{
        width: 4px;
    }
    .series-box::-webkit-scrollbar-thumb {
        border-radius: 2px;
        background: #d8d8d8;
    }
    .year-box{
        font-size: 20px;
        padding: 0 0 20px 20px;
    }

    /*
     * 增加学科筛选
     */
    .subject-select {
        margin: 10px 0 25px;
        padding-left: 14px;
        cursor: default;
    }
    .item-info {
        cursor: pointer;
        padding: 0 8px;
    }
    .active-item {
        color: #22B5AD;
    }
</style>
<style>
html::-webkit-scrollbar{
        width: 4px;
    }
    html::-webkit-scrollbar-thumb {
        border-radius: 2px;
        background: #d8d8d8;
    }
</style>