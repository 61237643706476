<template>
    <div>
       <Modal width='40%' class-name='modal-share' v-model="modalConfig.visable" :title="modalConfig.info.ShortName">
            <div class="describe-word" v-if="isValid(modalConfig.info.Introduction) && modalConfig.info.Introduction != ''" v-html="modalConfig.info.Introduction"></div>
            <div class="no-data" v-else>
                该系列暂时没有简介哦~
            </div>
            <div slot="footer" style="display:none;">
            </div>
       </Modal>
    </div>
</template>
    
<script>
export default {
    name: "",
    props: {
        modalConfig: {
            type: Object
        }
    },
    data () {
        return {
             
        };
    }
}
</script>
    
<style lang="css" scoped>
    .describe-word{
        min-height: 230px;
        max-height: 330px;
        overflow: auto;
        font-size: 14px;
    }
    .no-data{
        font-size: 14px;
        width: 100%;
        text-align: center;
    }
</style>