<template>
    <div class="col-xs-3 res-cropper" :class="{'res-item-phone':isPhone,'no-padding-right':isPhone && (idx+1) % 2 == 0}">
        <div class="res-item" :class="{'phone-item':isPhone}">
            <div class="cover">
                <div class="gexinghua" v-if="info.IsPersonalizedTraining">个性化训练</div>
                <div class="zhizhixunlian" v-if="info.IsPhysicalTraining"></div>
                <img class="cover-img" :src="resCover" alt="" @click="onResDetailClick" :class="{'phone-img':isPhone}">
            </div>
            <div class="res-name" :class="{'phone-res-name':isPhone}">{{ info.ShortName }}</div>
            <div class="icon" @click="onDetailClick">
                ?
            </div>
            <!-- <div class="operate-btn clearfix" :class="{'phone-operate-btn':isPhone}">
                <span :class="{'col-xs-offset-6':!isPhone}">
                    <span @click="onDetailClick" class="book-item"><span class="book-des" :class="{'phone-des':isPhone}">课程简介</span></span>
                </span>
            </div> -->
        </div>
        <res-desc-modal :modalConfig="detailConfig" v-if="isValid(detailConfig.info)"></res-desc-modal>
    </div>
</template>
    
<script>
import ResDescModal from './ResDescModal.vue'
export default {
    name: "",
    components: {
        ResDescModal
    },
    props:['info','isPhone','idx'],
    data () {
        return {
            detailConfig: {
                visable: false, 
                info: null
            }
        };
    },
    computed: {
        resCover() {
            if(this.isValid(this.info.Cover) && this.info.Cover != '') {
                return this.info.Cover
            } else{
                if(this.$route.path == '/Res/Zkfz' || this.$route.path == '/Res/Gkfx') {
                    return '/static/images/res/zk_default.png'
                } else {
                    return '/static/images/res/ts_default.png'
                }
            }
        }
    },
    methods: {
        // 系列简介
        onDetailClick() {
            this.detailConfig.visable = true
            this.detailConfig.info = this.info
        },
        // 系列详情
        onResDetailClick() {
                let alias = ''
                if(this.$route.path == '/Res/Zkfz') {
                    alias = 'Zkfz'
                } else if (this.$route.path == '/Res/Gkfx') {
                    alias = 'Gkfx'
                } else if (this.$route.path == '/Res/Zkfx') {
                    alias = 'Zkfx'
                } else if (this.$route.path == '/Res/Tbcp') {
                    alias = 'Tbcp'
                } else if (this.$route.path == '/Res/Jbzy') {
                    alias = 'Jbzy'
                } else if (this.$route.path == '/Res/Tszt') {
                    alias = 'Tszt'
                } else if (this.$route.path == '/Res/Ztxx') {
                    alias = 'Ztxx'
                } else {
                   alias = 'Tszy' 
                }
                let hidenav = this.$route.query.hidenav
                this.$router.push({
                    // path: '/Res/SpecialDetail',
                    path:'/Res/SunNewDetail',
                    query: {
                        id: this.info.SeriesIdx,
                        type: alias,
                        title:this.info.ShortName,
                        hidenav:hidenav?hidenav:undefined,
                        ispersonalized: this.info.IsPersonalizedTraining
                    }
                })
        }
    }
}
</script>
    
<style lang="css" scoped>
    .res-item{
        background-color: #fff;
        border-radius: 3px;
        min-height: 310px;
        padding-top: 24px;
        border: 1px solid #D4DFE7;
    }
    .res-cropper{
        margin-bottom: 14px;
        padding-right: 0px;
    }
    .res-cropper:nth-child(4n){
        padding-right: 0px!important;
    }
    .res-cropper:nth-child(4n + 1){
        /* padding-left: 0px!important; */
    }
    .cover{
        position: relative;
    }
    .cover > img{
        display: block;
        margin: 0 auto;
        object-fit: contain;
        height: 220px;
        max-width: 160px;
        cursor: pointer;
        box-shadow: 0px 2px 6px #989696;
        border-radius: 3px;
    }
    .res-name{
        width: 100%;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 28px 10px 19px 10px;
        font-size: 18px;
        color: #1B2A51;
        border-bottom: 1px solid #EEEEEE;
    }
    .book-item{
        cursor: pointer;
    }
    .book-des{
        color: #22B5AD;
        vertical-align: text-bottom;
        display: inline-block;
        border: 1px solid #2DCCC4;
        border-radius: 2px;
        width: 92px;
        height: 28px;
        line-height: 28px;
    }
    .el-icon-document{
        color: #8999DD;
        font-size: 21px;
    }
    .el-icon-tickets{
        color: #4EA5F5;
        font-size: 21px;
    }
    .operate-btn{
        text-align: center;
        margin-top: 10px;
        height: 50px;
        line-height: 50px;
    }
    .operate-btn /deep/ .ivu-btn-info{
        background-color: #39D2C7;
        border-color: #39D2C7;
        padding: 4px 19px;
    }
    .operate-btn /deep/ .ivu-btn-info:first-child{
        margin-right: 10px;
    }
    .res-item-phone{
        width: 50% !important;
    }
    .no-padding-right{
        padding-right: 0px !important;
    }
    .phone-img{
        height: 150px !important;
    }
    .phone-item{
        min-height: 120px !important;
    }
    .phone-res-name{
        padding: 8px 10px 8px 10px !important;
    }
    .phone-icon{
        font-size: 15px !important;
    }
    .phone-des{
        font-size: 12px !important;
    }
    .phone-operate-btn{
        padding: 5px !important;
    }
    .zhizhixunlian{
        position: absolute;
        width: 50px;
        height: 50px;
        background: url('/static/images/res/sun-kczx/zhizhixunlian.png');
        background-size: 100%;
        left: -90px;
        right: 0;
        bottom: 5px;
        margin:auto;
    }
    .gexinghua{
        position: absolute;
        color: #FFF;
        background: #FF9653E5;
        width: 80px;
        height: 24px;
        left: 0;
        right: -80px;
        top: 0;
        margin:auto;
        text-align: center;
        line-height: 24px;
    }
    .icon{
        display: inline-block;
        width: 20px;
        height: 20px;
        border: 1px solid #22B9B1;
        position: absolute;
        border-radius: 50%;
        top: 15px;
        right: 15px;
        text-align: center;
        color: #22B9B1;
        user-select: none;
        cursor: pointer;
    }
</style>